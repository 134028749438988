import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['collapse']

  changeIcon () {
    const collapse = this.collapseTarget
    const direction = collapse.className.includes('collapsed') ? 'right' : 'down'
    collapse.firstChild.className = `fal fa-chevron-circle-${direction}`
  }
}
