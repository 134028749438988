(function () {
  document.addEventListener('turbo:load', function () {
    var locale
    locale = {
      OK: 'OK',
      CONFIRM: I18n.t('shared.bootbox.confirm'),
      CANCEL: I18n.t('shared.bootbox.cancel')
    }
    return window.bootbox.addLocale('custom', locale)
  })
}).call(this)
