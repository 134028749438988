/*
  Removes element after 5 seconds
*/
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    setTimeout(() => this.element.remove(), 5000)
  }
}
