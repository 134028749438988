import { Controller } from '@hotwired/stimulus'
import bootbox from 'bootbox' // this is actually not necessary, however eslint will complain otherwise
import { jsonFetch } from '../src/javascripts/fetch_helper'

export default class extends Controller {
  static targets = ['checkbox', 'progressBar', 'destroySelectionButton', 'downloadSelectionButton', 'selectAllFilesButton', 'undoSelectionButton', 'encryptedUploadToggle']

  connect () {
    this.resetSelection()
    this.handleFileCheckBoxClick()
    this.updateLockIcon()
  }

  // enable destroy button if at least one file is selected, disable otherwise
  handleFileCheckBoxClick () {
    if (!this.hasDestroySelectionButtonTarget) return
    const selectedFiles = this.checkboxTargets.filter((e) => e.checked)
    this.setDestroyButtonStatus(selectedFiles.length)
    this.setDownloadButtonStatus(selectedFiles.length)
    const noFilesSelected = selectedFiles.length === 0
    this.updateSelectionButtons(noFilesSelected)
  }

  setDestroyButtonStatus (enabled) {
    const action = enabled ? 'remove' : 'add'
    this.destroySelectionButtonTarget.classList[action]('disabled')
  }

  setDownloadButtonStatus (enabled) {
    const action = enabled ? 'remove' : 'add'
    this.downloadSelectionButtonTarget.classList[action]('disabled')
  }

  setFilesStatus (selected) {
    this.checkboxTargets.forEach((fileCheckbox) => {
      fileCheckbox.checked = selected
    })
    this.setDestroyButtonStatus(selected)
    this.updateSelectionButtons(!selected)
    this.setDownloadButtonStatus(selected)
  }

  selectAllFiles () {
    this.setFilesStatus(true)
  }

  undoSelection () {
    this.setFilesStatus(false)
  }

  // switch between select all and undo selection button
  updateSelectionButtons (showSelectAll) {
    const selectAllClassList = this.selectAllFilesButtonTarget.classList
    const undoClassList = this.undoSelectionButtonTarget.classList
    if (showSelectAll) {
      selectAllClassList.remove('d-none')
      undoClassList.add('d-none')
    } else {
      selectAllClassList.add('d-none')
      undoClassList.remove('d-none')
    }
  }

  destroySelection (event) {
    const checkedFiles = this.checkboxTargets.filter((fileCheckbox) => fileCheckbox.checked)
    const fileIdsToDelete = checkedFiles.map((checkbox) => checkbox.value)
    const path = this.destroySelectionButtonTarget.dataset.path
    const receiver = this.destroySelectionButtonTarget.dataset.receiver || this.destroySelectionButtonTarget.name
    const message = I18n.t(`js.files.${receiver}.multipleDestroy`, { count: fileIdsToDelete.length })
    if (fileIdsToDelete.length && confirm(message)) {
      const data = JSON.stringify({ ids: fileIdsToDelete })
      jsonFetch(path, 'DELETE', data)
        .then(function (response) {
          if (response.status === 200) this.handleAfterDestroySelection(path)
        }.bind(this))
    }
  }

  downloadSelection (event) {
    const checkedFiles = this.checkboxTargets.filter((fileCheckbox) => fileCheckbox.checked)
    const encrypted = checkedFiles.filter((fileToDownload) => fileToDownload.dataset.encrypted === 'true').length
    const ids = checkedFiles.map((fileToDownload) => fileToDownload.value)
    encrypted ? this.downloadEncryptedFiles(ids) : this.downloadFiles(ids)
    this.undoSelection()
  }

  downloadFiles (ids) {
    const data = JSON.stringify({ ids: ids })
    this.initiateDownloadFilesRequest(data)
  }

  downloadEncryptedFiles (ids) {
    bootbox.prompt({
      title: I18n.t('shared.bootbox.title'),
      locale: 'custom',
      inputType: 'password',
      animate: 'false',
      backdrop: 'true',
      callback: (password) => {
        if (password) {
          const data = JSON.stringify({ ids: ids, encryption: { file_password: password } })
          this.initiateDownloadFilesRequest(data)
        }
      }
    })
  }

  initiateDownloadFilesRequest (data) {
    const path = this.downloadSelectionButtonTarget.dataset.path
    jsonFetch(path, 'POST', data)
      .then((res) => res.status === 200 ? res : res.json().then(err => { throw err }))
      .then(res => res.blob())
      .then((blob) => this.downloadFileFromBlob(blob))
  }

  downloadFileFromBlob (blob) {
    const newBlob = new Blob([blob])
    const blobUrl = window.URL.createObjectURL(newBlob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', 'files.zip')
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(blobUrl)
  }

  // remove files from the table & redirect if all files have been destroyed
  handleAfterDestroySelection (path) {
    this.checkboxTargets.filter((fileCheckbox) => fileCheckbox.checked).map((file) => file.parentNode.parentNode.parentNode.remove())
    this.handleFileCheckBoxClick()
    if (this.checkboxTargets.length === 0) location.reload()
  }

  resetSelection () {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
  }

  updateLockIcon () {
    if (!this.hasEncryptedUploadToggleTarget) return

    const checked = this.encryptedUploadToggleTarget.checked
    const icon = document.querySelector('.form-switch .form-check-label i')
    const faIcon = checked ? 'fa-lock-alt' : 'fa-lock-open-alt'
    icon.classList = `fal ${faIcon}`
  }

  toggleEncryption () {
    const path = this.encryptedUploadToggleTarget.dataset.path
    jsonFetch(path, 'PUT')
    this.updateLockIcon()
  }

  downloadWithPassword () {
    event.preventDefault()
    const submitBtn = event.target
    const form = submitBtn.closest('form')
    const input = document.getElementById(`password_for_${form.id}`)
    var box = bootbox.prompt({
      title: I18n.t('shared.bootbox.title'),
      locale: 'custom',
      inputType: 'password',
      animate: 'false',
      backdrop: 'true',
      callback: function (password) {
        if (password) {
          input.setAttribute('value', password)
          form.submit()
          setTimeout(() => {
            submitBtn.disabled = false // enables download file after submit
            input.value = ''
          }, 1000)
        }
      }
    })
    box.find('.btn-secondary').removeClass('btn-secondary').addClass('btn-outline-dark')
  }
}
