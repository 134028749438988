import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['company', 'subdomain', 'subdomainTouched', 'subdomainWithHost']

  updateSubdomain () {
    if (this.subdomainTouched) return
    const subdomain = this.companyTarget.value.toLowerCase()
    this.subdomainTarget.value = subdomain
    this.cleanSubdomain()
    this.updateSubdomainInfo()
  }

  markTouched () {
    this.cleanSubdomain()
    this.updateSubdomainInfo()
    this.subdomainTouched = true
  }

  cleanSubdomain () {
    this.subdomainTarget.value = this.subdomainTarget.value
      .toLowerCase()
      .replace(/\s/g, '')
      .replace(/[^a-zA-Z0-9]/g, '')
      .substring(0, 20)
  }

  updateSubdomainInfo () {
    const div = document.getElementById('subdomain-div')
    this.subdomainTarget.value === '' ? div.classList.add('d-none') : div.classList.remove('d-none')
    const fullTenantPath = `${this.subdomainTarget.value}.${window.location.host}/`
    this.subdomainWithHostTarget.textContent = fullTenantPath
  }
}
