function csrfToken () {
  return document.querySelector("[name='csrf-token']").content
}

function jsonFetch (path, requestMethod, body) {
  return fetch(`${path}`, {
    headers: {
      'X-CSRF-Token': csrfToken(),
      'Content-Type': 'application/json'
    },
    method: requestMethod,
    body: body
  })
}

export { csrfToken, jsonFetch }
