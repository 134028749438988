import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  connect () {
    /* eslint-disable no-new */
    new TomSelect(this.element, {
      plugins: ['remove_button'],
      onItemAdd: function () {
        this.setTextboxValue('')
        this.refreshOptions()
      },
      placeholder: this.element.dataset.placeholder,
      hidePlaceholder: true,
      render: {
        no_results: function (data, escape) {
          return `<div class="no-results">${this.input.dataset.noResults} ${escape(data.input)}</div>`
        }
      }
    })
  }
}
