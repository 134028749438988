import '../src/javascripts/jquery'
import 'bootstrap'

import tippy from 'tippy.js'
import bootbox from 'bootbox'

import '../controllers'
import '../src/javascripts/tippy'
import '../src/javascripts/localization'
import '../src/javascripts/bootbox'
import '../src/javascripts/fetch_helper'

import * as Turbo from '@hotwired/turbo'

window.tippy = tippy
window.bootbox = bootbox

Turbo.start()
