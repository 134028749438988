import { Controller } from '@hotwired/stimulus'
import Dropzone from 'dropzone'
import { csrfToken } from '../src/javascripts/fetch_helper'

export default class extends Controller {
  static targets = ['input']

  connect () {
    this.dropzone = createDropzone(this)
    this.hideFileInput()
    this.bindEvents()
    Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput () {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = 'none'
  }

  bindEvents () {
    this.dropzone.on('queuecomplete', event => {
      toggleSubmitBtn(this)
    })

    this.dropzone.on('sending', (file, xhr, data) => {
      toggleSubmitBtn(false)
      modifyData(file, data)
    })

    this.dropzone.on('success', (file, response) => {
      handleUploadSuccess(file, response, this)
    })

    this.dropzone.on('error', file => {
      handleError(file, this)
    })

    this.dropzone.on('dragover', event => {
      toggleDropPreview(true)
    })

    this.dropzone.on('drop', event => {
      toggleDropPreview(false)
    })

    this.dropzone.on('dragleave', event => {
      setTimeout(() => toggleDropPreview(false), 500)
    })
  }

  get headers () {
    return { 'X-CSRF-Token': csrfToken() }
  }

  get url () {
    return this.data.get('upload-url')
  }

  get maxFiles () {
    return 5
  }

  get maxFilesize () {
    return 100
  }

  get acceptedFiles () {
    return this.data.get('acceptedFiles')
  }

  get previewTemplate () {
    return document.getElementById('dz-preview-template').innerHTML
  }
}

function toggleSubmitBtn (controller) {
  const button = document.getElementById('upload-btn')
  if (controller && controller.element.dropzone.files.length) {
    button.parentNode.classList.remove('d-none')
  } else {
    button.parentNode.classList.add('d-none')
  }
}

function toggleDropPreview (dragging) {
  const emptyPreview = document.getElementById('drop-preview')
  if (dragging) emptyPreview.classList.remove('d-none')
  else emptyPreview.classList.add('d-none')
}

function fetchJobStatus (file, controller) {
  const path = location.href.split('?')[0] + `/job_progress?job_id=${file.job_id}`
  fetch(path)
    .then((data) => {
      return data.json()
    })
    .then((data) => {
      if (data.job.status === 'complete') {
        updatePreview(file, 'remove', 'dz-scanning')
        enablePopover(file, data)
        if (data.job.message.includes('Virus')) {
          alert(data.job.message)
          updatePreview(file, 'add', 'dz-error')
          handleError(file, controller)
        } else if (file.encrypted) {
          updatePreview(file, 'add', 'dz-encrypted')
        } else {
          updatePreview(file, 'add', 'dz-scanned')
        }
      } else setTimeout(() => { fetchJobStatus(file) }, 1000)
    })
}

function modifyData (file, data) {
  file.encrypted = fileEncryptionEnabled()
  data.append('customer[file]', file)
  data.append('customer[encrypt_file]', file.encrypted)
  data.append('mailing_list[file]', file)
  data.append('mailing_list[encrypt_file]', file.encrypted)
}

function fileEncryptionEnabled () {
  const checkbox = document.querySelectorAll("[id*='encrypt_files']")[0]
  return checkbox && checkbox.checked
}

function handleUploadSuccess (file, response, controller) {
  updatePreview(file, 'remove', 'dz-processing')
  updatePreview(file, 'add', 'dz-scanning')
  if (file.encrypted) updatePreview(file, 'remove', 'dz-image-preview')
  file.job_id = response.job_id
  setTimeout(() => { fetchJobStatus(file, controller) }, 500)
}

function enablePopover (file, data) {
  const scanIcon = file.previewElement.getElementsByClassName('scan-popover')[0]
  window.tippy(scanIcon, { theme: 'mandacom', content: data.job.message, placement: 'top-end' })
}

function handleError (file, controller) {
  setTimeout(() => {
    controller.dropzone.removeFile(file)
    toggleSubmitBtn(controller)
  }, 4000)
}

function updatePreview (file, method, klass) {
  file.previewElement.classList[method](klass)
}

function createDropzone (controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFilesize,
    acceptedFiles: controller.acceptedFiles,
    previewTemplate: controller.previewTemplate,
    addRemoveLinks: false,
    autoQueue: true,
    paramName: 'file',
    createImageThumbnails: true,
    dictDefaultMessage: I18n.t('shared.files_upload.select_multiple_files'),
    dictMaxFilesExceeded: I18n.t('shared.files_upload.too_many_files', { max: controller.maxFiles }),
    dictFileTooBig: I18n.t('shared.files_upload.file_too_big', { max: controller.maxFilesize }),
    dictInvalidFileType: I18n.t('shared.files_upload.only_allowed_files')
  })
}
