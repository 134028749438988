// this controller can be used to load server rendered views after the page load
// simply add data-controller="content-loader" and the URL for the remote path
// (data-content-loader-url="/some_path") to the element where you want to
// insert the html

import { Controller } from '@hotwired/stimulus'
import { csrfToken } from '../src/javascripts/fetch_helper'
export default class extends Controller {
  connect () {
    this.load()
  }

  load () {
    fetch(this.data.get('url'), {
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      return response.text()
    })
      .then(html => {
        this.element.innerHTML = html
      })
  }
}
